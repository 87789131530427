import React from "react"

import Layout from "../components/layout"
import LogosReferences from '../components/LogosReferences'

import { HeadComponent } from "../components/HeadComponent"

import { floatImage } from "./solutions.module.scss"

import map_spreadsheep from "../gifs/map_spreadsheep.gif"

const Solutions = () => {
  
  return (
    <Layout>
      <HeadComponent title="Solutions - Cartographie Collaborative avec Spreadsheep" />
      <img
        className={floatImage}
        src={map_spreadsheep}
        alt="module de cartographie avec Spreadsheep"
      ></img>
      <div >
        <h1>Outils d'aide à la décision pour l'Aménagement</h1>
        L'urbanisme en mode projet et la collaboration entre acteurs aux nombre
        croissant sont les éléments clés des processus de l'aménagement et de
        l'urbansime contemporain. Les nombreuses données disponibles ainsi que
        de nouveaux outils numériques apportent des solutions afin de disposer,
        dans des délais très courts, d'outils métiers sur mesure et
        collaboratifs.
        <hr />
        <h1>Spreadsheep : la cartographie collaborative en quelques clics</h1>
        <p>
          Kadata est éditeur de la solution{" "}
          <a
            href="https://spreadsheep.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spreadsheep.
          </a>
          <br />
          Importez, collectez vos données et collaborez efficacement avec la
          puissance d'une base de données. Comme beaucoup de petites équipes,
          automatisez votre activité avec simplicité en quelques clics.
          <br />
          <LogosReferences />
          Mis en oeuvre dans de nombreuses études pour faciliter les échanges
          entre bureau d'étude et maîtrise d'ouvrage, mais aussi pour gagner en
          productivité en interne, Spreadsheep permet de réduire les délais de
          validation, favorise l'appropriation des études et permet de faire
          vivre les résultats des travaux engagés.
          <br />
          Spreadsheep est la solution idéale pour créer et partager rapidement
          un référentiel foncier ou immobilier.
        </p>
      </div>
    </Layout>
  )
}


export default Solutions
